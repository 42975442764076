import { Box, Typography } from "@mui/material";
import React from "react";

export const PageHeader: React.FC<{ title: string; description?: string }> = ({
  title,
  description,
}) => {
  return (
    <Box>
      <Typography variant="h6">{title}</Typography>
      <Typography variant="body2">{description}</Typography>
    </Box>
  );
};
