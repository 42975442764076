const PRICE_PER_DAY = 5;

export interface Task {
  id: string;
  title: string;
  description?: string;
  points?: number;
  assignee?: string | null;
  avatar?: string | null;
}

export interface Person {
  id: string;
  name: string;
  avatar: string;
}

export interface Rating {
  percent: number;
}

type PersonId = string;

type DateString = string;

export type RatingsForPersons = Record<PersonId, Rating>;

export type Ratings = Record<DateString, RatingsForPersons>;

export type StatsType = Record<PersonId, number>;

export const mergeTasks = (
  configTasks: Task[],
  currentTasks: Task[] | undefined
): Task[] => {
  return configTasks.map((task) => {
    const currentTask = currentTasks?.find((t) => t.id === task.id);
    const retVal = currentTask ? { ...currentTask, ...task } : task;
    //console.log(task, currentTask, retVal);
    return retVal;
  });
};

export const recalculateStats = (
  ratings: Ratings,
  monthAndYear: Date
): StatsType => {
  const stats: Record<PersonId, number> = {};
  Object.keys(ratings).forEach((date) => {
    const d = new Date(date);
    if (
      d.getMonth() !== monthAndYear.getMonth() ||
      d.getFullYear() !== monthAndYear.getFullYear()
    ) {
      return;
    }

    Object.keys(ratings[date]).forEach((personId) => {
      if (!stats[personId]) {
        stats[personId] = 0;
      }
      const v = ratings[date][personId]?.percent;
      stats[personId] += v && v > 1 ? PRICE_PER_DAY * (v / 100) : 0;
    });
  });
  return stats;
};
