import { doc } from "firebase/firestore";
import { useEffect, useMemo, useState } from "react";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { mergeTasks, Person, Task } from "../data/models";
import { firestore } from "../firebase";

export const useDataHook = (familyId: string) => {
  const [localUpdate, setLocalUpdate] = useState(false);

  const [tasks, setTasks] = useState<Task[]>([]);
  const [people, setPeople] = useState<Person[]>([]);

  const [configValue, configLoading, configError] = useDocumentData(
    doc(firestore, "config", familyId)
  );
  const [currentTasksValue, currentTasksLoading, currentTasksError] =
    useDocumentData(doc(firestore, "currentTasks", familyId));

  // Set the tasks from Firestore using useEffect
  useEffect(() => {
    if (!configLoading && configValue && !currentTasksLoading && !localUpdate) {
      // If there is already configured "currentTasks", use them
      // if not, use the list of all tasks, so user can configure them for first time
      setTasks(mergeTasks(configValue.tasks, currentTasksValue?.tasks));

      setPeople(configValue.children);
      console.log("Calling setTasks");
    }
  }, [configLoading, configValue, currentTasksValue, currentTasksLoading]);

  const pointsMap = useMemo(() => {
    return people.reduce((acc, person) => {
      acc[person.id] = tasks
        .filter((task) => task.assignee === person.id)
        .reduce((sum, task) => sum + (task.points || 0), 0);
      return acc;
    }, {} as Record<string, number>);
  }, [tasks, people]);

  const tasksForPersonMap = useMemo(() => {
    return people.reduce((acc, person) => {
      acc[person.id] = tasks
        .filter((task) => task.assignee === person.id)
        .map((task) => task.title);
      return acc;
    }, {} as Record<string, string[]>);
  }, [tasks, people]);

  return {
    localUpdate,
    setLocalUpdate,
    tasks,
    setTasks,
    people,
    pointsMap,
    tasksForPersonMap,
  };
};
