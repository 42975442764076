import CloseIcon from "@mui/icons-material/Close";
import {
  Avatar,
  Badge,
  Box,
  Card,
  CardContent,
  Chip,
  Container,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { doc, setDoc } from "firebase/firestore";
import React from "react";
import { isMobile } from "react-device-detect";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from "react-dnd-touch-backend";
import { PageHeader } from "../components/Header";
import { Person, Task } from "../data/models";
import { firestore } from "../firebase";
import { useDataHook } from "../hooks/dataHook";

const ItemTypes = {
  PERSON: "person",
};

const TaskCard: React.FC<{
  task: Task;
  onDrop: (personId: string) => void;
}> = ({ task, onDrop }) => {
  const [{ isOver }, drop] = useDrop({
    accept: ItemTypes.PERSON,
    drop: (item: { id: string }) => {
      onDrop(item.id);
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });

  const handleRemoveAssignee = () => {
    // Action to trigger when the "x" is clicked
    // For example, you can remove the assignee from the task
    onDrop("");
  };

  return (
    <Card
      ref={drop}
      sx={{ marginBottom: 2, backgroundColor: isOver ? "#f0f0f0" : "white" }}
    >
      <CardContent>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box>
            <Typography variant="h6">{task.title}</Typography>
            <Typography variant="body2">{task.description}</Typography>
            <Chip label={task?.points} variant="outlined" size="small" />
          </Box>
          {task?.assignee && task?.avatar && (
            <Box position="relative" display="inline-block">
              <Avatar src={task?.avatar} />
              <IconButton
                size="small"
                sx={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  transform: "translate(50%, -50%)",
                  backgroundColor: "white",
                  "&:hover": {
                    backgroundColor: "white",
                  },
                }}
                onClick={handleRemoveAssignee}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </Box>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

const PersonAvatar: React.FC<{ person: Person }> = ({ person }) => {
  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.PERSON,
    item: { id: person.id },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  return (
    <Avatar
      ref={drag}
      src={person.avatar}
      sx={{
        margin: 1,
        cursor: "pointer",
        opacity: isDragging ? 0.5 : 1,
        // width: 56,
        // height: 56,
      }}
    />
  );
};

const TaskCards: React.FC = () => {
  const {
    localUpdate,
    setLocalUpdate,
    tasks,
    setTasks,
    people,
    pointsMap,
    tasksForPersonMap,
  } = useDataHook("kmochovi");

  const handleDrop = async (taskId: string, personId: string) => {
    const updatedTasks = tasks.map((task) =>
      task.id === taskId
        ? {
            ...task,
            assignee: personId ? personId : null,
            avatar: personId
              ? people.find((p) => p.id === personId)?.avatar
              : null,
          }
        : task
    );
    console.log(updatedTasks);

    setLocalUpdate(true);
    setTasks(updatedTasks);
    const docRef = doc(firestore, "currentTasks", "kmochovi");
    await setDoc(docRef, { tasks: updatedTasks });
    setTimeout(() => setLocalUpdate(false), 500);
  };

  return (
    <>
      <Box sx={{ padding: 2 }}>
        <PageHeader
          title="Nastavení úkolů"
          description="Přiřaďte úkoly dětem přetažením ikonky na úkol"
        />
      </Box>
      <DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
        <Box
          sx={{
            position: "sticky",
            top: 0,
            backgroundColor: "background.default", // Use the theme primary color
            color: "text.primary",
            padding: 2,
            zIndex: 1100, // Ensure it appears above other elements
          }}
        >
          {people?.map((person) => (
            <Badge
              key={person.id}
              badgeContent={pointsMap[person.id]}
              color="primary"
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              sx={{
                "& .MuiBadge-badge": {
                  transform: "translate(0%, 10%)",
                },
              }}
            >
              <PersonAvatar key={person.id} person={person} />
            </Badge>
          ))}
        </Box>
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {tasks.map((task) => (
                <TaskCard
                  key={task.id}
                  task={task}
                  onDrop={(personId) => handleDrop(task.id, personId)}
                />
              ))}
            </Grid>
          </Grid>
        </Container>
      </DndProvider>
    </>
  );
};

export default TaskCards;
function dataHook(arg0: string): {
  localUpdate: any;
  setLocalUpdate: any;
  tasks: any;
  people: any;
  pointsMap: any;
} {
  throw new Error("Function not implemented.");
}
