// Helper function to format date
export const formatDate = (date: Date) => {
  const options: Intl.DateTimeFormatOptions = {
    weekday: "long",
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  };
  return date.toLocaleDateString("cs-CZ", options);
};

export const getLastNDays = (n: number) => {
  const dates = [];
  const today = new Date();
  for (let i = 0; i <= n; i++) {
    const date = new Date(today);
    date.setDate(today.getDate() - i);
    dates.push(date);
  }
  return dates;
};

export const formatPriceToCZK = (price: number) => {
  return price.toFixed(2).replace(".", ",").replace(",00", "") + " Kč";
};
