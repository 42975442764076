import { Box, Button, Typography } from "@mui/material";
import { signInWithPopup } from "firebase/auth";
import React from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, authProvider } from "../firebase";

const LoginLogoutPage: React.FC<{ onFullscreen: () => void }> = ({
  onFullscreen,
}) => {
  const [user, loading, error] = useAuthState(auth);

  const handleLogin = () => {
    signInWithPopup(auth, authProvider)
      .then((result) => {
        console.log("User signed in: ", result.user);
      })
      .catch((error) => {
        console.error("Error during sign in: ", error);
      });
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      minHeight="100vh"
    >
      <Button
        variant="contained"
        onClick={() => handleLogin()}
        sx={{ width: "70%", mb: 2 }}
        disabled={user?.email ? true : false}
      >
        Login
      </Button>
      <Button
        variant="contained"
        sx={{ width: "70%", mb: 2 }}
        onClick={() => onFullscreen()}
      >
        Fullscreen
      </Button>
      <Button
        variant="contained"
        onClick={() => auth.signOut()}
        disabled={!user?.email}
        sx={{ width: "70%" }}
      >
        Logout
      </Button>
      <Typography mt={5}>
        {user?.email ? `Přihlášen jako ${user.email}` : "Nepřihlášen"}
      </Typography>
    </Box>
  );
};

export default LoginLogoutPage;
